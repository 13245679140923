import { DialogTitle } from "@headlessui/react"
import clsx from "clsx"
import { FC, MouseEventHandler, PropsWithChildren, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import Button from "src/components/base/buttons/Button"
import Input from "src/components/base/forms/Input"
import Icon from "src/components/base/icon/Icon"
import Modal from "src/components/base/modals/Modal"
import ModalContent from "src/components/base/modals/ModalContent"

export interface ModalConfirmationProps extends PropsWithChildren {
  cancelBtnLabel?: string
  message?: string
  okBtnLabel?: string
  onAccept: () => Promise<void> | void
  onClose: () => void
  onGoBack?: () => void
  onDeny?: () => Promise<void> | void
  show: boolean
  title?: string
  confirmValue?: string
  variant?: "default" | "warning" | "destructive"
}

const variantToIcon = {
  default: undefined,
  destructive: "warning",
  warning: "exclamation"
} as const

const variantToIconClass = {
  default: undefined,
  destructive: "text-red-600",
  warning: "text-orange-600"
} as const

const variantToCancelBtnLabel = {
  default: "Cancel",
  destructive: "Cancel",
  warning: "Cancel"
}

const variantToOkBtnLabel = {
  default: "Apply",
  destructive: "Delete",
  warning: "Apply"
}

const variantToOkBtnColor = {
  default: "primary",
  destructive: "danger",
  warning: "primary"
} as const

type FormSchema = { confirmValue?: string }

const ModalConfirmation: FC<ModalConfirmationProps> = ({
  cancelBtnLabel,
  confirmValue,
  children,
  message,
  okBtnLabel,
  show,
  variant = "default",
  title = "Are you sure?",
  ...callbacks
}: ModalConfirmationProps) => {
  const hasIcon = !!variantToIcon[variant]
  const [loadingState, setLoadingState] = useState<"accept" | "deny">()
  const form = useForm<FormSchema>()

  const onAccept = form.handleSubmit(async () => {
    setLoadingState("accept")

    try {
      await callbacks.onAccept()
      callbacks.onClose()
    } finally {
      setLoadingState(undefined)
    }
  })

  const onDeny: MouseEventHandler = async e => {
    setLoadingState("deny")

    try {
      e.preventDefault()
      await callbacks.onDeny?.()
      callbacks.onClose()
    } finally {
      setLoadingState(undefined)
    }
  }

  useEffect(() => {
    form.reset()
  }, [form, show])

  return (
    <Modal show={show} onDismiss={callbacks.onClose}>
      <ModalContent className="relative m-md w-[calc(100vw-2rem)] max-w-xl p-md">
        <div className="mb-md flex items-center justify-between gap-xs align-middle">
          <DialogTitle className="title !mb-0 flex items-center gap-xs">
            {hasIcon && (
              <Icon
                className={variantToIconClass[variant]!}
                icon={variantToIcon[variant]!}
                size={24}
                type="solid"
              />
            )}
            {title}
          </DialogTitle>
          <Button
            aria-label="Close"
            className="!px-0.5 !text-grey-600"
            color="tertiary"
            size="small"
            type="button"
            onClick={callbacks.onClose}
          >
            <Icon className="text-grey-800" icon="cross-big" size={16} type="solid" />
          </Button>
        </div>

        <form id="confirmModalForm" noValidate onSubmit={onAccept}>
          <FormProvider {...form}>
            {children || message}

            {confirmValue && (
              <Input {...form.register("confirmValue")} autoFocus className="mt-sm" />
            )}
          </FormProvider>

          <div
            className={clsx(
              "mt-md flex items-center align-middle",
              callbacks.onGoBack && "justify-between"
            )}
          >
            <div>
              {callbacks.onGoBack && (
                <Button
                  aria-label="Back"
                  color="tertiary"
                  type="button"
                  onClick={callbacks.onGoBack}
                >
                  Go back
                </Button>
              )}
            </div>
            <div className="flex justify-end gap-sm">
              <Button
                aria-label="Cancel"
                color="secondary"
                isLoading={loadingState === "deny"}
                type="button"
                onClick={onDeny}
              >
                {cancelBtnLabel || variantToCancelBtnLabel[variant]}
              </Button>
              <Button
                aria-label="Apply"
                autoFocus
                color={variantToOkBtnColor[variant]}
                disabled={!!confirmValue && confirmValue !== form.watch("confirmValue")?.trim()}
                isLoading={loadingState === "accept"}
                type="submit"
              >
                {okBtnLabel || variantToOkBtnLabel[variant]}
              </Button>
            </div>
          </div>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default ModalConfirmation
